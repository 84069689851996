<template>
  <div class="grafisch">
    <header>
      <div>
        <img @click="$router.go(-1)" src="@assets/back.png"  class="back-button"/>
        <img src="@assets/grafisch/header.png" />
      </div>
    </header>

    <div class="uk-flex uk-flex-center uk-grid-large uk-child-width-1-2">
      <img
        @click="openModal('cover-1.jpg', '#1D1E19')"
        src="@assets/grafisch/cover-1.jpg"
      />
    </div>

    <div
      class="uk-margin-large-top uk-flex uk-flex-center uk-grid-large uk-child-width-1-2"
    >
      <img
        @click="openModal('cover-2.jpg', '#1D1E19')"
        src="@assets/grafisch/cover-2.jpg"
      />
    </div>

    <div
      @mouseenter="dropDown = true"
      class="uk-margin-large-top uk-flex uk-flex-center uk-grid-large uk-child-width-1-4"
    >
      <img
        @click="openModal('visite-kaartje-1.jpg', '#353D8E')"
        src="@assets/grafisch/visite-kaartje-1.jpg"
      />
      <img
        @click="openModal('visite-kaartje-1-back.jpg', '#353D8E')"
        src="@assets/grafisch/visite-kaartje-1-back.jpg"
      />
    </div>
        <div
      @mouseenter="dropDown = true"
      class="uk-margin-large-top uk-flex uk-flex-center uk-grid-large uk-child-width-1-4"
    >
      <img
        @click="openModal('visite-kaartje-1.jpg', '#353D8E')"
        src="@assets/grafisch/shirt-achter-1.jpg"
      />
      <img
        @click="openModal('visite-kaartje-1-back.jpg', '#353D8E')"
        src="@assets/grafisch/shirt-achter-2.jpg"
      />
    </div>

    <div
      class="uk-margin-large-top uk-flex uk-flex-center uk-grid-large uk-child-width-1-4"
    >
      <img
        @click="openModal('visite-kaartje-2.jpg', '#FFFFFF')"
        src="@assets/grafisch/visite-kaartje-2.jpg"
      />
      <img
        @click="openModal('visite-kaartje-2-back.jpg', '#FFFFFF')"
        src="@assets/grafisch/visite-kaartje-2-back.jpg"
      />
    </div>

    <div
      class="uk-margin-large-top uk-flex uk-flex-center uk-grid-large uk-child-width-1-2"
    >
      <img
        @click="openModal('poster.jpg', '#313232')"
        src="@assets/grafisch/poster.jpg"
      />
    </div>

    <div
      class="uk-margin-large-top uk-flex uk-flex-center uk-grid-large uk-child-width-1-2"
    >
      <img
        @click="openModal('museum-docent.gif', '#313232')"
        src="@assets/grafisch/museum-docent.gif"
      />
    </div>

    <div class="films-2">
     
      <h3 class="uk-text-center">
      </h3>
<div class="uk-container">
      </div>
    </div>

    <div @click="closeModal" class="uk-modal-full" ref="modal" uk-modal>
      <div
        :style="{ background: modalBackground }"
        class="uk-modal-dialog uk-modal-body uk-padding-remove modal"
      >
        <img
          class="uk-position-center"
          v-if="modalFile"
          :src="require(`@assets/grafisch/${modalFile}`)"
        />
      </div>
    </div>

            <footer class="uk-padding-large">
      <div>
        <img @click="$router.go(-1)" src="@assets/back.png"  class="back-button"/>
      </div>
    </footer>
  </div>
</template>

<script>
import UIkit from "uikit";

export default {
  data() {
    return {
      modalFile: null,
      modalBackground: null,
    };
  },

  methods: {
    openModal(file, background) {
      this.modalFile = file;
      this.modalBackground = background;

      UIkit.modal(this.$refs.modal).show();
    },

    closeModal() {
      this.modalFile = null;
      this.modalBackground = null;

      UIkit.modal(this.$refs.modal).hide();
    },
  },
};
</script>

<style lang="less" scoped>
.grafisch {
  min-height: 100vh;
  background: #d35725;
}

img {
  cursor: pointer;
}

.modal {
  height: 100vh;
  padding: 20vw;
  img {
    display: block;
    max-width: 80vw;
    max-height: 80vh;
    width: auto;
    height: auto;
  }
}
</style>
